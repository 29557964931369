import React, { useContext, useState } from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import "./style.css";
import { crm_options, table_data } from "../Data/data";
import { useNavigate } from "react-router-dom";
import { UserAddOutlined, InfoCircleOutlined } from "@ant-design/icons";
import AppContext from "../Context/AppContext";
import { toast } from "react-toastify";
import { createUser } from "../firebase/users.firebase";
const AddClient = () => {
  const navigate = useNavigate();
  const { setUsers } = useContext(AppContext);
  const [tableData] = useState(() => {
    return table_data;
  });
  const onFinish = async (values) => {
    console.log("Success:", values);
    try {
      const user_obj = {
        business: values.Business,
        crm: values.CRM,
        login_url: values["Login URL"],
        username: values.Username,
        password: values.Password,
        google_sheet_url: values["Google Sheet URL"],
        app_pwd: values["App Password"],
      };
      await createUser(values.Business, user_obj);
      toast.success("Edit Successfull");
      setUsers((users) => [...users, user_obj]);
      navigate("/");
    } catch (err) {
      toast.err(err.message);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    toast.error("Failed Adding Data.");
  };
  const showInfo = () => {
    Modal.info({
      title: "Help",
      content: (
        <div>
          <h4>
            <u>Sheet Link</u>
          </h4>
          <ol>
            <li>
              Share target google sheet with the service account (whose creds
              are uploaded to backend)
              <br />
            </li>
            <li>
              Add the target google sheet link in the <b>Google Sheet URL</b>{" "}
              below.
            </li>
          </ol>
        </div>
      ),
    });
  };
  return (
    <div className="sketchy_addclient">
      <Button style={{ float: "right", cursor: "pointer" }} onClick={showInfo}>
        <InfoCircleOutlined />
        Help
      </Button>
      <h2 style={{ fontFamily: "sans-serif", marginBottom: 70 }}>
        Add Client <UserAddOutlined />{" "}
      </h2>
      <Form
        name="basic"
        key={JSON.stringify(tableData)}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelCol={{
          span: 4,
        }}
      >
        {Object.keys(tableData).map((d) => (
          <Form.Item
            key={d}
            label={<b>{d}</b>}
            name={d}
            initialValue={tableData[d]}
            rules={[
              {
                required: true,
                message: `${d} field is required!`,
              },
            ]}
          >
            {d === "CRM" ? <Select options={crm_options} /> : <Input />}
          </Form.Item>
        ))}

        <Form.Item
          wrapperCol={{
            offset: 4,
          }}
        >
          <Button type="primary" htmlType="submit">
            Add Client
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddClient;

import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const CenteredDiv = styled.div`
div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Loading = () => (
    <CenteredDiv>
        <Spin size='large' />
    </CenteredDiv>
);
export default Loading;
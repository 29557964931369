import React, { useState, useContext, useEffect } from "react";
import { Table, Checkbox } from "antd";
import AppContext from "../../../Context/AppContext";
import {
  updateAdminPermissions,
  getUserProfile,
} from "../../../firebase/admins.firebase";

export default function AdminTable({ headerExtras }) {
  const [user, setUser] = useState(null);
  const { admins, setAdmins } = useContext(AppContext);

  useEffect(() => {
    getUserProfileDetails();
  }, []);
  const getUserProfileDetails = async () => {
    let u = await getUserProfile();
    setUser(u);
  };

  const setAdminsArr = (arr) => {
    if (!arr) return [];
    return Object.keys(arr).map((key) => ({ ...arr[key] }));
  };

  const adminsArray = setAdminsArr(admins);

  const updateUsersPermissions = async (item, value, field) => {
    let defaultPermissions = item.permissions || {};
    const permissions = {
      ...defaultPermissions,
      [field]: value,
    };
    let allUsers = await updateAdminPermissions(item, permissions);
    setAdmins(allUsers);
  };

  const renderCheckbox = (record, permission) => {
    const adminPermissions = record?.permissions || {};
    const hasPermission = adminPermissions[permission];

    return (
      <Checkbox
        checked={hasPermission}
        onChange={(e) =>
          updateUsersPermissions(record, e.target.checked, permission)
        }
      />
    );
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      width: 200,
    },
    {
      title: "Edit",
      width: 200,
      render: (_, record) => renderCheckbox(record, "edit"),
    },
    {
      title: "Add",
      key: "add",
      width: 200,
      render: (_, record) => renderCheckbox(record, "add"),
    },
    {
      title: "Delete",
      key: "delete",
      width: 200,
      render: (_, record) => renderCheckbox(record, "delete"),
    },
    {
      title: "View",
      key: "view",
      width: 150,
      render: (_, record) => renderCheckbox(record, "view"),
    },
  ];

  return (
    console.log("user", user),
    (
      <Table
        scroll={{ x: 1000 }}
        className="sketchy_table"
        dataSource={adminsArray}
        columns={columns}
        title={() => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2 style={{ color: "darkblue" }}>Admins Database</h2>
            {headerExtras}
          </div>
        )}
        bordered
      />
    )
  );
}

import { Modal } from 'antd'
import React from 'react'
import { useNavigate } from "react-router-dom"
import { LogoutOutlined } from '@ant-design/icons'
import "./style.css"

export default function Header({ logOut }) {
    const navigate = useNavigate()
    function handleClick() {
        navigate("")
    }
    const handleLogout = () => {
        Modal.confirm({
            title: "Log out from the application?",
            onOk: () => {
                logOut()
            }
        })
    }
    return (
        <div className='header'>
            <div className='sketchy' onClick={handleClick}>
                Client CRM Sync Manager
            </div >
            <div className='sketchy-button' onClick={handleLogout}>Logout <LogoutOutlined />
            </div>
        </div>
    )
}

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { toast } from "react-toastify";
import Dashboard from "./Dashboard/Dashboard";
import AddClient from "./AddClient/AddClient";
import EditClient from "./EditClient/EditClient";
import Header from "./components/Header/Header";
import { getUsers } from "./firebase/users.firebase";
import {
  ReadUserData,
  //createAdmin,
  getUserProfile,
} from "./firebase/admins.firebase";
import AppContext from "./Context/AppContext";
import Loading from "./components/Loading/Loading";
import { logOut } from "./firebase/auth.firebase";
import AddAdmin from "./AddAdmin/AddAdmin";
import AddAdminDashboard from "./Dashboard/AddAdminDashboard/AddAdminDashboard";
import Wrapper from "./wrapper/Wrapper";
export default function AppRoutes() {
  //fetching all the data one time here
  const [users, setUsers] = useState(undefined);
  const [admins, setAdmins] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  async function fetchUsers() {
    try {
      const users = await getUsers();
      setUsers(users);
      // console.log("Users List", users);
    } catch (err) {
      console.log(err);
      setUsers([]);
      if (err.message === "Cannot convert undefined or null to object") {
        toast.error("No users yet!");
      } else {
        toast.error(err.message);
      }
    }
  }

  async function fetchAdmins() {
    try {
      const admins = await ReadUserData();
      setAdmins(admins);
    } catch (err) {
      console.log(err);
      setAdmins([]);
      if (err.message === "Cannot convert") {
        toast.error("no admins at all");
      } else {
        toast.error(err.message);
      }
    }
  }
  const getCurrentUser = async () => {
    const user = await getUserProfile();
    setCurrentUser(user);
  };

  useEffect(() => {
    fetchUsers();
    fetchAdmins();
    getCurrentUser();
  }, []);

  const handleLogout = async () => {
    try {
      await logOut();
    } catch (err) {
      toast.err(err.message);
    }
  };

  if (users === undefined) return <Loading />;

  return (
    <AppContext.Provider
      value={{
        users,
        setUsers,
        fetchUsers,
        fetchAdmins,
        admins,
        setAdmins,
        currentUser,
        setCurrentUser,
      }}
    >
      <Router>
        <Header logOut={handleLogout} />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/admins/dashboard"
            element={
              <Wrapper>
                <AddAdminDashboard />
              </Wrapper>
            }
          />
          <Route path="/add" element={<AddClient />} />
          <Route path="/admins/add" element={<AddAdmin />} />
          <Route path="/edit/:business" element={<EditClient />} />
        </Routes>
      </Router>
    </AppContext.Provider>
  );
}

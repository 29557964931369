import { ref, set, onValue } from "firebase/database";
//import { toast } from "react-toastify";
import { db, auth } from "./setup.firebase";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { uuid } from "../utils/uuid";

export const getUserProfile = async () =>
  auth.currentUser.email ? await getUserByEmail(auth.currentUser.email) : null;

export const getUserAuth = () => auth.currentUser;

export const getUserByEmail = (email) => {
  const userProfileRef = ref(db, "admins");
  return new Promise((resolve, reject) => {
    onValue(
      userProfileRef,
      async (snapshot) => {
        const data = await snapshot.val();

        let user = Object.values(data).find((user) => user.Email === email);
        resolve(user);
      },
      { onlyOnce: true }
    );
  });
};
export const ReadUserData = () => {
  const userProfileRef = ref(db, "admins");
  return new Promise((resolve, reject) => {
    onValue(
      userProfileRef,
      async (snapshot) => {
        const data = await snapshot.val();
        resolve(data);
      },
      { onlyOnce: true }
    );
  });
};
export const WriteUserData = async (user) => {
  const defaultUserData = await ReadUserData();
  return new Promise((resolve, reject) => {
    const userProfileRef = ref(db, "admins");
    const userId = uuid();
    set(userProfileRef, {
      ...defaultUserData,
      [userId]: {
        ...user,
        id: userId,
      },
    })
      .then((e) => {
        resolve();
      })
      .catch((error) => {
        console.log("erre", error);
        reject(error);
      });
  });
};
export const updateAdminPermissions = async (user, permissions = {}) => {
  const allUsers = await ReadUserData();
  const allUpdatedUsers = { ...allUsers, [user.id]: { ...user, permissions } };
  new Promise((resolve, reject) => {
    const usersRef = ref(db, "admins");
    set(usersRef, allUpdatedUsers)
      .then((e) => {
        resolve();
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });

  return allUpdatedUsers;
};

async function createAdmin(email, password) {
  const auth = getAuth();
  await createUserWithEmailAndPassword(auth, email, password);
  await WriteUserData({
    Email: email,
    Password: password,
  });

  return await getUserByEmail(email);
  // return user;
}

export { createAdmin };

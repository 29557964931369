import React from "react";
import { Navigate } from "react-router-dom";
import { getUserAuth } from "../firebase/admins.firebase";
const superAdmins = ["v.saberwal@dialedinbookkeeping.com", "ben@dialedinbookkeeping.com"]; // Update with your list of super admin users

const Wrapper = ({ children }) => {
  const currentUser = getUserAuth();
  if (currentUser && superAdmins.includes(currentUser?.email)) return children;
  else return <Navigate to="/" />;
};

export const isSuperAdmin = () => {
  const currentUser = getUserAuth();
  if (superAdmins.includes(currentUser?.email)) return true;
  return false;
};

export default Wrapper;

import { get, ref, set } from "firebase/database";
import { toast } from "react-toastify";
import { db } from "./setup.firebase";

async function getUsers() {
  const users = (await get(ref(db, "users/"))).val();
  return [...Object.values(users)];
}

async function createUser(id, data) {
  const userExists = (await get(ref(db, "users/" + id))).val();
  if (userExists) {
    return toast("User with this ID already exists", { type: "error" });
  }
  await set(ref(db, "users/" + id), data);
}

async function deleteUser(id) {
  await set(ref(db, "users/" + id), null);
}

async function updateUser(id, updatedObject) {
  const user = (await get(ref(db, "users/" + id))).val();
  await set(ref(db, "users/" + id), { ...user, ...updatedObject });
}

export { createUser, deleteUser, updateUser, getUsers };

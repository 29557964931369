import React from 'react';
import { Button, Form, Input } from 'antd';
import "./style.css"
import { toast } from 'react-toastify';
import { signIn } from '../firebase/auth.firebase';
import { ArrowRightOutlined } from '@ant-design/icons';
const Login = ({ setUser }) => {
    const onLogin = async ({ username, password }) => {
        setUser(undefined)
        try {
            const user = await signIn(username, password)
            console.log(user)
            setUser(user)
        } catch (err) {

            toast.error(err.message)
            setUser(null)
        }
    };
    const onFinishFailed = (errorInfo) => {
        toast.error("Login Error")
    };
    return (
        <div className='neobrutal_login shadow'>
            <h2 className='heading'>Client CRM Sync Manager</h2>
            <Form
                name="basic"
                onFinish={onLogin}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                labelCol={{
                    span: 5,
                }}
            >
                <Form.Item
                    label={"Username"}
                    name={"username"}
                    rules={[
                        {
                            required: true,
                            message: `Username is required!`,
                        },
                    ]}
                >
                    <Input className='inp' />
                </Form.Item>
                <Form.Item
                    label={"Password"}
                    name={"password"}
                    rules={[
                        {
                            required: true,
                            message: `Password is required!`,
                        },
                    ]}
                >
                    <Input.Password className='inp' />
                </Form.Item>
                <Form.Item
                    name={"submit"}
                    wrapperCol={{
                        offset: 5,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Login<ArrowRightOutlined />
                    </Button>
                </Form.Item>
            </Form>
        </div>

    );
};
export default Login;
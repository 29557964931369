import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import AdminTable from "../components/adminTable/AdminTable";
import "../style.css";

export default function Dashboard() {
  const navigate = useNavigate();

  function handleAddClient() {
    navigate("/admins/add");
  }

  return (
    <main>
      <div>
        <AdminTable
          headerExtras={
            <>
              <Button
                className="add_client"
                type="primary"
                size="large"
                onClick={handleAddClient}
              >
                Add New Admin
              </Button>
            </>
          }
        />
      </div>
    </main>
  );
}

import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";


const auth = getAuth();



export async function signIn(email, password) {
    const user = await signInWithEmailAndPassword(auth, email, password)
    return user
}


export async function logOut() {
    await signOut(auth)
}
import "./App.css";
import "./firebase/setup.firebase";
import AppRoutes from "./AppRoutes";

import { useEffect, useState } from "react";
import Login from "./Login/Login";

import "antd/dist/reset.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Loading from "./components/Loading/Loading";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function App() {
  const [user, setUser] = useState(undefined); //null: no user, undefined:loading

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  if (user === null) {
    return <Login setUser={setUser}></Login>;
  } else if (user === undefined) {
    return <Loading />;
  }

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default function AppContainer() {
  return (
    <>
      <App />
      <ToastContainer />
    </>
  );
}

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

import "./style.css";
import ClientTable from "./components/ClientTable";
import AppContext from "../Context/AppContext";
import { isSuperAdmin } from "../wrapper/Wrapper";

export default function Dashboard() {
  const { currentUser } = useContext(AppContext);
  const navigate = useNavigate();

  function handleAddClient() {
    navigate("add");
  }
  const superAdminUser = isSuperAdmin();

  return (
    console.log("superAdminUser", superAdminUser),
    (
      <main>
        <div>
          {/* {currentUser?.permissions?.view ? ( */}
          <ClientTable
            headerExtras={
              <>
                {superAdminUser && (
                  <Button
                    className="add_client"
                    type="primary"
                    size="large"
                    onClick={() => navigate("/admins/dashboard")}
                  >
                    Admin Management
                  </Button>
                )}
                {(superAdminUser || currentUser?.permissions?.add) && (
                  <Button
                    className="add_client"
                    type="primary"
                    size="large"
                    onClick={handleAddClient}
                  >
                    Add New Client
                  </Button>
                )}
              </>
            }
          />
          {/* ) : null} */}
        </div>
      </main>
    )
  );
}

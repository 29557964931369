import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import "./style.css";
import { crm_options, table_data } from "../Data/data";
import { useNavigate, useParams } from "react-router-dom";
import { EditFilled } from "@ant-design/icons";
import AppContext from "../Context/AppContext";
import { toast } from "react-toastify";
import { updateUser } from "../firebase/users.firebase";
const EditClient = () => {
  const navigate = useNavigate();
  const { business } = useParams();
  const { users, setUsers } = useContext(AppContext);
  const [tableData, setTableData] = useState(() => {
    return table_data;
  });
  useEffect(() => {
    const data = users.find((user) => user.business === business);
    setTableData({
      Business: data.business,
      CRM: data.crm,
      "Login URL": data.login_url,
      Username: data.username,
      Password: data.password,
      "Google Sheet URL": data.google_sheet_url,
      "App Password": data.app_pwd,
    });
  }, [users, business]);
  const onFinish = async (values) => {
    console.log("Success:", values);
    try {
      const update_obj = {
        business: values.Business,
        crm: values.CRM,
        login_url: values["Login URL"],
        username: values.Username,
        password: values.Password,
        google_sheet_url: values["Google Sheet URL"],
        app_pwd: values["App Password"],
      };
      await updateUser(business, update_obj);
      toast.success("Edit Successfull");
      setUsers((users) =>
        users.map((user) => (user.business === business ? update_obj : user))
      );
      navigate("/");
    } catch (err) {
      toast.err(err.message);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    toast.error("Failed Updating Data.");
  };
  return (
    <div className="sketchy_addclient">
      <h2 style={{ fontFamily: "sans-serif", marginBottom: 70 }}>
        Edit {business} <EditFilled />
      </h2>
      <Form
        name="basic"
        key={JSON.stringify(tableData)}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelCol={{
          span: 5,
        }}
      >
        {Object.keys(tableData).map((d) => (
          <Form.Item
            key={d}
            label={<b>{d}</b>}
            name={d}
            initialValue={tableData[d]}
            rules={[
              {
                required: true,
                message: `${d} field is required!`,
              },
            ]}
          >
            {d === "CRM" ? <Select options={crm_options} /> : <Input />}
          </Form.Item>
        ))}

        <Form.Item
          wrapperCol={{
            offset: 4,
          }}
        >
          <Button type="primary" htmlType="submit">
            Save Edit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default EditClient;

const table_data = {
  Business: "",
  CRM: "",
  "Login URL": "",
  Username: "",
  Password: "",
  "Google Sheet URL": "",
  "App Password": "change if jobber",
};

const crm_options = [
  {
    value: "Booking Koala",
    label: "Booking Koala",
  },
  {
    value: "Jobber",
    label: "Jobber",
  },
];

const addAdminTable_data = {
  Email: "",
  Password: "",
};

export { table_data, crm_options, addAdminTable_data };

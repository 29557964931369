import React, { useState, useContext } from "react";
import { Button, Form, Input } from "antd";
import { toast } from "react-toastify";
import { UserAddOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  createAdmin,
  //getUserByEmail,
} from "../../src/firebase/admins.firebase";
import AppContext from "../../src/Context/AppContext";
import { useNavigate } from "react-router-dom";
import { addAdminTable_data } from "../Data/data";

const AddAdmin = () => {
  const navigate = useNavigate();
  const { admins, setAdmins } = useContext(AppContext);
  const [adminTableData] = useState(() => {
    return addAdminTable_data;
  });
  const onFinish = async (values) => {
    try {
      let user = await createAdmin(values.Email, values.Password);
      setAdmins((e) => ({
        ...e,
        [user.id]: user,
      }));

      toast.success("Admin created successfully.");
      navigate("/admins/dashboard");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    toast.error("Failed adding data.");
  };

  return (
    console.log("contenxt>>>", admins),
    (
      <div className="sketchy_addclient">
        <Button style={{ float: "right", cursor: "pointer" }}>
          <InfoCircleOutlined />
          Help
        </Button>
        <h2 style={{ fontFamily: "sans-serif", marginBottom: 70 }}>
          Add Admin <UserAddOutlined />
        </h2>
        <Form
          name="basic"
          key={JSON.stringify(adminTableData)}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          labelCol={{
            span: 4,
          }}
        >
          <Form.Item
            label={<b>Email</b>}
            name="Email"
            rules={[
              {
                required: true,
                message: "Email field is required!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={<b>Password</b>}
            name="Password"
            rules={[
              {
                required: true,
                message: "Password field is required!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4 }}>
            <Button type="primary" htmlType="submit">
              Add Admin
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  );
};

export default AddAdmin;

//render the clients table
import { Table, Modal, Space } from "antd";
import React, { useContext, useState } from "react";
import "./style.css";

import {
  EditFilled,
  DeleteFilled,
  ExclamationCircleFilled,
  EyeFilled,
  EyeInvisibleFilled,
  CalendarOutlined,
  ClockCircleOutlined,
  CaretRightFilled,
  PauseCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AppContext from "../../Context/AppContext";
import { toast } from "react-toastify";
import { deleteUser, updateUser } from "../../firebase/users.firebase";
import moment from "moment";
import { isSuperAdmin } from "../../wrapper/Wrapper";

const { confirm } = Modal;

const TablePassword = ({ password }) => {
  const [isHidden, setIsHidden] = useState(true);
  return (
    <Space
      style={{
        posititon: "relative",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
      }}
      onClick={() => setIsHidden(!isHidden)}
    >
      {isHidden ? <p>••••••••••</p> : password}
      <div
        style={{
          position: "absolute",
          bottom: "0",
          right: "10px",
          fontSize: "20px",
          color: "darkgrey",
        }}
      >
        {isHidden ? <EyeFilled /> : <EyeInvisibleFilled />}
      </div>
    </Space>
  );
};

export default function ClientTable({ headerExtras }) {
  const navigate = useNavigate();
  const superAdminUser = isSuperAdmin();
  const { users, setUsers, fetchUsers, currentUser } = useContext(AppContext);
  const columns = [
    {
      title: "Last Updated At",
      render: (_, { date, last_err, err_complete }) => {
        if (!date) return;
        const dateObj = moment(date);
        return (
          <div style={{ fontSize: "13px" }}>
            <div>
              <CalendarOutlined /> {dateObj.format("DD-MM-YYYY")}
            </div>
            <div>
              <ClockCircleOutlined /> {dateObj.format("HH:mm")}
            </div>
            {last_err !== "" && last_err !== undefined && (
              <div
                style={{ color: "red" }}
                onClick={() => {
                  Modal.info({
                    width: "1000px",
                    title: "Last error complete",
                    content: err_complete,
                  });
                }}
              >
                <WarningOutlined /> {last_err}
              </div>
            )}
          </div>
        );
      },
      key: "lastUpdatedAt",
      width: 180,
    },
    {
      title: "Business",
      dataIndex: "business",
      key: "business",
      width: 200,
    },

    {
      title: "CRM",
      dataIndex: "crm",
      key: "crm",
      width: 150,
      filters: [
        {
          text: "Jobber",
          value: "Jobber",
        },
        {
          text: "Booking Koala",
          value: "Booking Koala",
        },
      ],
      onFilter: (value, record) => {
        console.log(record);
        return record.crm.indexOf(value) === 0;
      },
    },
    {
      title: "Login URL",
      dataIndex: "login_url",
      width: 300,
      render: (_, { login_url }) => {
        return (
          <a
            href={login_url}
            target={"_blank"}
            rel="noreferrer"
            style={{ color: "black", textDecoration: "underline" }}
          >
            {login_url}
          </a>
        );
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 250,
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (_, { password }) => <TablePassword password={password} />,
      width: 170,
    },
    {
      title: "Google Sheet URL",
      dataIndex: "google_sheet_url",
      key: "google_sheet_url",
      width: 300,
      render: (_, { google_sheet_url }) => {
        return (
          <a
            href={google_sheet_url}
            target={"_blank"}
            rel="noreferrer"
            style={{ color: "black", textDecoration: "underline" }}
          >
            {google_sheet_url}
          </a>
        );
      },
    },
    {
      title: "Actions",
      fixed: "right",
      width: 150,
      render: (_, record) => (
        <Space style={{ fontSize: 16 }}>
          {(superAdminUser || currentUser?.permissions?.edit) && (
            <EditFilled
              onClick={() => {
                navigate("edit/" + record.business);
              }}
              style={{ color: "darkblue" }}
              label="Edit"
            />
          )}
          {(superAdminUser || currentUser?.permissions?.delete) && (
            <DeleteFilled
              onClick={() => {
                confirm({
                  title: "Do you Want to delete?",
                  icon: <ExclamationCircleFilled />,
                  content: "This action is irreversible!",
                  onOk: async () => {
                    try {
                      await deleteUser(record.business);
                      setUsers(
                        users.filter(
                          (user) => user.business !== record.business
                        )
                      );
                      toast.warn("Client deleted!");
                    } catch (err) {
                      toast.error("Error Deleting Client");
                    }
                  },
                  onCancel() {
                    console.log("Cancel");
                  },
                });
              }}
              style={{ color: "darkred" }}
              label="Delete"
            />
          )}
          {(superAdminUser || currentUser?.permissions?.edit) && (
            <div
              onClick={() => {
                confirm({
                  title: `${!record.isPaused ? "Pause" : "Resume"} ${
                    record.business
                  }?`,
                  icon: <ExclamationCircleFilled />,
                  content: "You can pause/resume at any time.",
                  onOk: async () => {
                    try {
                      await updateUser(record.business, {
                        isPaused: !record.isPaused,
                      });
                      await fetchUsers();
                    } catch (err) {
                      toast.error("Error ");
                    }
                  },
                  onCancel() {
                    console.log("Cancel");
                  },
                });
              }}
            >
              {!record.isPaused ? (
                <PauseCircleOutlined />
              ) : (
                <CaretRightFilled />
              )}
            </div>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Table
      scroll={{
        x: 1000,
      }}
      className="sketchy_table"
      dataSource={users}
      columns={columns}
      title={() => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2 color="darkblue">Clients Database</h2>
          {headerExtras}
        </div>
      )}
      bordered
    />
  );
}
